body {
  background: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  font-family: "Muli", sans-serif;
  font-weight: 200;
  background-image: url(./images/pipes.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
